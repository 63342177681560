
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import Company from "@/types/Company";
//import Province from "@/types/Company";
import { Actions } from "@/store/enums/StoreEnums";
// import JwtService from "@/core/services/JwtService";
export default defineComponent({
  name: "account-settings",
  components: {
    Field,
  },
  setup() {
    const store = useStore();
    const districts = ref();
    const submitButtonSettings = ref<HTMLElement | null>(null);
    const selectedProvinceId = ref(0);
    const selectedDistrictId = ref(0);
    const province = ref();
    const district = ref();
    let company: Company = {
      name: "",
      address: "",
      districtId: 0,
      provinceId: 0,
      email: "",
      id: 0,
      telephone: "",
      districtName: "",
      provinceName: "",
    };
    store.dispatch(Actions.PROVINCE_LIST);
    store.dispatch(Actions.MY_PROFILE).then((f) => {
      company.name = f.company.name;
      company.address = f.company.address;
      company.email = f.company.email;
      company.telephone = f.company.telephone;

      if (f.company.provinceId) {
        company.provinceName = f.company.provinceName;
        company.provinceId = f.company.provinceId;
        province.value = {
          value: f.company.provinceId,
          name: f.company.provinceName,
        };
      }
      if (f.company.districtId) {
        company.districtName = f.company.districtName;
        company.districtId = f.company.districtId;
        districts.value = getDistricts.value.filter(
          (f) => f.id == company.provinceId
        )[0].districts;
        district.value = {
          value: f.company.districtId,
          name: f.company.districtName,
        };
      }
    });
    onMounted(() => {
      console.log("aaa");
    });

    const isClear = ref(false);
    const saveChanges = () => {
      company.districtId = district.value.id;
      company.districtName = district.value.name;
      company.provinceId = province.value.value;
      company.provinceName = province.value.name;
      
    };

    const selectedProvince = (args) => {
      if (args.value) {
        districts.value = getDistricts.value.filter(
          (f) => f.id == args.value.value
        )[0].districts;
        district.value = null;
        selectedProvinceId.value = args.value.value;
      } else {
        selectedProvinceId.value = 0;
        selectedDistrictId.value = 0;
        districts.value = null;
        district.value = null;
        isClear.value = true;
      }
    };

    const selectedDistrict = (args) => {
      if (args.value) {
        selectedDistrictId.value = args.value.id;
      } else {
        selectedDistrictId.value = 0;
      }
    };
    const getProvince = computed(
      () => store.state.ProvinceModule.province.value || []
    );
    const getDistricts = computed(
      () => store.state.ProvinceModule.province.value || []
    );
    const getCompany = computed(
      () => store.state.AuthModule.user.value.company
    );
    onMounted(() => {
      setCurrentPageBreadcrumbs("Ayarlar", []);
    });

    return {
      saveChanges,
      submitButtonSettings,
      Field,
      province,
      selectedProvince,
      districts,
      selectedDistrict,
      selectedProvinceId,
      selectedDistrictId,
      isClear,
      getCompany,
      company,
      getProvince,
      district,
    };
  },
});
